class LazyArticles {
  constructor() {
    this.query = "";
    this.loading = false;
    this.topic = window.location.pathname.split("/").pop();
    this.language = window.location.pathname.split("/")[1];
    this.loader = document.querySelector(".lds-dual-ring");
    this.region = "";
    this.listen();
  }

  listen() {
    if (this.language == "see") {
      this.region = "see";
    }
    if (this.language.length > 2 && this.language !== "nordic") {
      this.language = "en";
    }

    const lazytagsEl = document.querySelector("#lazytags");
    if (lazytagsEl) {
      const tagsString = lazytagsEl.getAttribute("data-tags");
      this.topic = "";
      const tags = tagsString.split(", ");
      tags.forEach((tag, index) => {
        this.topic += tag;
        if (index < tags.length - 1) {
          this.topic += "&topics=";
        }
      });
    }

    const container = document.querySelector(".page-tag__list");
    document.addEventListener("scroll", () => {
      const scrollable = container.scrollHeight - window.innerHeight;

      if (window.scrollY >= scrollable) {
        if (!this.loading) {
          this.loading = true;
          const endCursorEl = document.querySelector("[data-cursor]");
          let endCursor = endCursorEl.getAttribute("data-cursor");

          if (endCursor === "null") {
            this.loader.remove(); // Remove loader if no more results
            return;
          }

          fetch(`/api/news?languages=${this.language}&topics=${this.topic}&cursor=${endCursor}&regions=${this.region}`)
            .then((res) => res.json())
            .then((data) => {
              if (data.items && data.items.length > 0) {
                data.items.forEach((item) => {
                  if (item.access === "FREE") {
                    const link = `/${this.language}/news/${item.id}/${item.slug}`;
                    const clone = document
                      .querySelector("#news-item")
                      .content.cloneNode(true);
                    const el = clone.querySelector(".news-item");
                    el.querySelector(".news-item__media-container a").href =
                      link;
                    el.querySelector(".news-item__title a").href = link;
                    el.querySelector(".news-item__title a").innerHTML =
                      item.title;
                    if (item.featuredImage) {
                      el.querySelector(".news-item__image").src =
                        item.featuredImage.url;
                      el.querySelector(".news-item__image").alt =
                        item.featuredImage.alt ?? "";
                    } else {
                      el.classList.add("news-item--no-image");
                      el.querySelector(".news-item__media-container").remove();
                    }
                    if (item.access == "PURCHASABLE") {
                      el.classList.add("news-item--blocked");
                    }
                    if (item.topics) {
                      let topicString = "";
                      for (let i = 0; i < item.topics.length; i++) {
                        topicString += `<a href="/${this.language}/topics/${item.topics[i].id}">${item.topics[i].name.toUpperCase()}</a>`;
                        if (i != item.topics.length - 1) topicString += "|";
                      }
                      el.querySelector(".news-item__topics").innerHTML =
                        topicString;
                    } else {
                      el.querySelector(".news-item__topics").remove();
                    }
                    const d = new Intl.DateTimeFormat("en-GB", {
                      dateStyle: "short",
                      timeStyle: "short",
                    }).format(new Date(item.publicationDate));
                    el.querySelector(".news-item__date").innerHTML = d
                      .replaceAll("/", ".")
                      .replaceAll(",", "");
                    container.appendChild(el);
                  }
                });

                // Update cursor
                endCursorEl.setAttribute("data-cursor", data.endCursor);

                // Stop fetching if no more results
                if (!data.endCursor || data.endCursor === "null") {
                  this.loader.remove();
                } else {
                  container.appendChild(this.loader);
                }
              } else {
                this.loader.remove(); // No items returned, stop loading
              }
              this.loading = false;
            });
        }
      }
    });
  }
}

export default LazyArticles;
