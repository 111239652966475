import { jwtDecode } from "jwt-decode"

class Authentication {
  constructor() {
    this.article_id = window.location.pathname;
    //-- Staging --//
    this.authURL = "https://authenticate-dev.montelnews.com"
    this.applicationID = "dd44303d-539a-4e81-9fb8-029d9b83e2f3"
    this.base_url = "https://mnt-d-euw-news-api-svc.azurewebsites.net"
    //-- Production  --//
    if (window.location.host == 'montelnews.com') {
      this.authURL = "https://authenticate.montelnews.com"
      this.applicationID = "bbecba16-9932-419a-8670-d9d8ae209148"
      this.base_url = "https://news-api.montelgroup.com"
    }
    this.validate();
  }

  showContent(token) {
    const container = document.querySelector(".article__body");
    fetch(`${this.base_url}/News/GetArticleById?platform=MontelNews&id=${this.get_article_id()}`,
      { headers: { 'Authorization': `Bearer ${token}` } }
    ).then((res) => {
      res.json().then(data => {
        container.innerHTML = data.contentHtml;
      })
    });
    let footer_bylines = document.querySelector("footer.article__bylines");
    if (footer_bylines) {
      footer_bylines.classList.add("show");
    }
  }

  get_article_id() {
    let urlFragments = location.pathname.split("/");
    if (urlFragments[1] == "news") {
      return urlFragments[2];
    }
    return urlFragments[3];
  }

  is_article() {
    let urlFragments = location.pathname.split("/");
    if (urlFragments.includes("news")) {
      return true;
    }
    return false;
  }

  get_language() {
    let urlFragments = location.pathname.split("/");
    if (urlFragments[1] == "news") {
      return "en";
    }
    return urlFragments[1];
  }

  validate() {
    fetch(
      `${this.authURL}/validate?applicationId=${this.applicationID}`,
      { credentials: "include" },
    ).then((res) => {
      if (res.status === 200) {
        // Validated
        const el = document.querySelector('.logged-in-email')
        res.text().then(token => {
          const email = jwtDecode(token).email
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'customerId': email,
          });
          if (el) el.innerHTML = email
          if (this.is_article()) {
            this.showContent(token);
          }
        })
        this.showLoggedInButtons()
        const trials = document.querySelectorAll(".nav-trial");
        if (trials.length > 0) {
          trials.forEach((trial) => trial.remove());
        }
      } else {
        // Try to refresh token
        fetch(
          `${this.authURL}/refresh?applicationId=${this.applicationID}`,
          { credentials: "include" },
        )
          .then((res) => {
            if (res.ok) {
              // Has refresh token, i.e logged in
              res.text().then(token => {
                const email = jwtDecode(token).email
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  'customerId': email,
                });
                const el = document.querySelector('.logged-in-email')
                if (el) el.innerHTML = email
                this.showLoggedInButtons()
                const trials = document.querySelectorAll(".nav-trial");
                if (trials.length > 0) {
                  trials.forEach((trial) => trial.remove());
                }
                if (this.is_article()) {
                  this.showContent();
                }
              })
            } else {
              // Can't refresh, not logged in
              this.showLoggedOutButtons()
            }
          })
      }
    })
      .catch(error => {
        if (window.location.host === 'montelnews.com' || window.location.host === 'test.montelnews.com') {
          this.showLoggedOutButtons()
        }
      })
  }

  showLoggedInButtons() {
    // Show avatar, show logout
    document.querySelector(".avatar").classList.remove("avatar--hidden")
    document.querySelectorAll('.button--logout').forEach(button => button.classList.remove('button--hidden'))
    document.querySelectorAll('.button--register').forEach(button => button.classList.add('button--hidden'))
    document.querySelectorAll('.button--login').forEach(button => button.classList.add('button--hidden'))
  }

  showLoggedOutButtons() {
    // Show login, show register
    document.querySelectorAll('.button--register').forEach(button => button.classList.remove('button--hidden'))
    document.querySelectorAll('.button--login').forEach(button => button.classList.remove('button--hidden'))
    document.querySelector(".avatar").classList.add("avatar--hidden")
    document.querySelectorAll('.button--logout').forEach(button => button.classList.add('button--hidden'))
  }
}

export default Authentication;