class Modal {

  constructor(el) {
    this.el = el
    this.formButtonListeners()
    this.exitListeners()
  }


  exitListeners() {
    this.el.addEventListener('click', (ev) => {
      if (ev.target.classList.contains('modal__close')) {
        this.hideModal()
      }
      if (ev.target.classList.contains('modal')) {
        this.hideModal()
      }
    })
    addEventListener("keydown", (ev) => {
      if (ev.key === 'Escape') {
        this.hideModal()
      }
    })
  }

  formButtonListeners() {
    const target = this.el.id
    const button = document.querySelector(`[data-target="${target}"]`)
    button.addEventListener('click', () => {
      this.el.classList.remove('modal--hidden')
      document.documentElement.classList.add('noscroll')
    })
  }

  hideModal() {
    this.el.classList.add('modal--hidden')
    document.documentElement.classList.remove("noscroll")
  }

}

export default Modal