import ApexCharts from "apexcharts";

class EnergyViewer {
  app() {
    this.init();
    this.addListeners();
  }

  widget() {
    this.initSelectors();
    this.fuelmixDailyChart();
    this.addListeners();
  }

  init() {
    this.initCheckbox();
    this.initSelectors();
    this.fuelmixDailyChart();
    document
      .querySelector(".energy-viewer__fuelmix")
      .setAttribute("data-type", "daily");
    this.priceAndCarbonCharts();
  }

  constructor() {
    this.isFirstLoad = true; // Define as a class property
  }

  // Reset checkbox to daily view on load
  initCheckbox() {
    document.querySelector("[name=view][value=daily]").checked = true;
  }

  initSelectors() {
    const regions = document.querySelector("#regions");
    if (localStorage.getItem("region") && regions) {
      regions.value = localStorage.getItem("region");
    }

    const currencies = document.querySelector("#currencies");
    if (localStorage.getItem("currency") && currencies) {
      currencies.value = localStorage.getItem("currency");
    }
  }

  // Generate Fuelmix Daily View (Pie Chart)
  fuelmixDailyChart() {
    // Check if region is set in session, else default is AT
    let region = "AT";
    if (localStorage.getItem("region")) {
      region = localStorage.getItem("region");
    }
    // Default timezone is CET
    let timezone = "CET";

    // Check if edition is in URL, else default is english
    let language = "en";
    const firstUrlFragment = window.location.pathname.split("/")[1];
    if (firstUrlFragment.length == 2) {
      language = firstUrlFragment;
    }
    if (firstUrlFragment == "nordic") {
      language = "no";
    }
    //Hide the timestamp at all times on dailychart
    const timeDisplay = document.querySelector("#timeDisplay");
    if (timeDisplay) {
      timeDisplay.innerText = " "
    }

    // Get selected date
    let dateEl = document.querySelector("#current-date");
    let dateArr = [];
    if (dateEl) {
      dateArr = dateEl.getAttribute("data-date").split("/");
    } else {
      const today = new Date();
      dateArr[0] = today.getFullYear();
      dateArr[1] = (today.getMonth() + 1).toString().padStart(2, "0");
      dateArr[2] = today.getDate().toString().padStart(2, "0");
    }

    const querystring = `/api/enappsys/chart/daily?region=${region}&year=${dateArr[0]}&month=${dateArr[1]}&date=${dateArr[2]}&timezone=${timezone}&language=${language}`;
    // console.log(querystring)

    // Get the daily data
    fetch(
      `/api/enappsys/chart/daily?region=${region}&year=${dateArr[0]}&month=${dateArr[1]}&date=${dateArr[2]}&timezone=${timezone}&language=${language}`,
    ).then((res) =>
      res.json().then((dailyData) => {
        // Generate legends
        const legendContainer = document.querySelector(
          "#fuelmix-legend-container",
        );
        legendContainer.innerHTML = "";
        dailyData.values.forEach((value, index) => {
          const template = document.querySelector("#fuelmix-button-template");
          const clone = template.content.cloneNode(true);
          const button = clone.firstElementChild;
          button.setAttribute("data-target", value);
          button.setAttribute("data-index", index);
          button.querySelector(".enappsys__color-dot").style.backgroundColor =
            dailyData.colors[index];
          button.querySelector(".enappsys__button-label").innerText =
            dailyData.labels[index];
          button.querySelector(".enappsys__button-percent").innerText =
            dailyData.values[index].toFixed(1) + "%";
          legendContainer.appendChild(button);
        });

        const existingChart = document.querySelector("#fuelmix-daily-chart");
        if (existingChart) existingChart.innerHTML = "";

        // Generate chart with Apex Charts
        this.setupFuelmixDailyChart(dailyData);

        // Generate label
      }),
    );
  }

  // Generate Fuelmix Hourly View (Stacked Area Chart)
  fuelmixHourlyChart() {
    // Get necessary data
    const timezone = "CET";
    const dateEl = document.querySelector("#current-date");
    const today = new Date();
    let region = document.querySelector("#regions").value;
    let language = "en";
    const firstUrlFragment = window.location.pathname.split("/")[1];
    if (firstUrlFragment.length == 2) {
      language = firstUrlFragment;
    }
    if (firstUrlFragment == "nordic") {
      language = "no";
    }

    let date = [today.getFullYear(), today.getMonth() + 1, today.getDate()];
    if (dateEl) {
      date = dateEl.getAttribute("data-date").split("/");
    }

    // console.log(region)
    fetch(
      `/api/enappsys/chart/hourly?region=${region}&year=${date[0]}&month=${date[1]}&date=${date[2]}&timezone=${timezone}&language=${language}`,
    ).then((res) =>
      res.json().then((hourlyData) => {
        // Sort legends in descending order
        const legendContainer = document.querySelector(
          "#fuelmix-legend-container",
        );
        legendContainer.innerHTML = "";
        const descendingData = hourlyData.toSorted((a, b) => {
          return b.data[0].y - a.data[0].y;
        });
        // console.log(descendingData)

        descendingData.forEach((item, index) => {
          const template = document.querySelector("#fuelmix-button-template");
          const clone = template.content.cloneNode(true);
          const button = clone.firstElementChild;
          button.setAttribute("data-target", item.key);
          button.setAttribute("data-index", index);
          button.querySelector(".enappsys__color-dot").style.backgroundColor =
            item.color;
          button.querySelector(".enappsys__button-label").innerText = item.name;
          button.querySelector(".enappsys__button-percent").innerText =
            item.data[0].y.toFixed(1) + "MW";
          legendContainer.appendChild(button);
        });

        const chart = document.querySelector("#fuelmix-hourly-chart");
        chart.innerHTML = "";

        this.setupFuelmixHourlyChart(hourlyData);
      }),
    );
  }

  priceAndCarbonCharts() {
    const selectedRegion = document.querySelector("#regions").value;
    const selectedCurrency = document.querySelector("#currencies").value;
    const currencySymbol = document
      .querySelector("#currencies")
      .selectedOptions[0].getAttribute("symbol");

    let region = selectedRegion;
    if (localStorage.getItem("region")) {
      region = localStorage.getItem("region");
    }
    let currency = selectedCurrency;
    if (localStorage.getItem("currency")) {
      currency = localStorage.getItem("currency");
    }

    const symbolCurrency = document.querySelector("#price-currency-symbol");

    if (symbolCurrency) {
      symbolCurrency.innerHTML = currencySymbol;
    }

    const timezone = "CET";
    const todayEl = document.querySelector("#current-date");
    const date = todayEl.getAttribute("data-date");
    const language = document.documentElement.lang;

    fetch(
      `/api/enappsys/chart/price-carbon?region=${region}&date=${date}&currency=${currency}&timezone=${timezone}&language=${language}"`,
    ).then((res) =>
      res.json().then((data) => {
        this.priceChart(data);
        this.carbonChart(data);
      }),
    );
  }

  // Generate Price chart (Hourly)
  priceChart(data) {
    // Remove existing chart
    const chart = document.querySelector("#price-chart");
    chart.innerHTML = "";
    this.setupPriceChart(data);
  }

  // Generate Carbon chart (Hourly)
  carbonChart(data) {
    const chart = document.querySelector("#carbon-chart");
    chart.innerHTML = "";
    this.setupCarbonChart(data);
  }

  // Add all event listeners for application here
  addListeners() {
    // Listen to view selector (Daily vs Hourly)
    const fuelmixViewSelector = document.querySelector(
      ".energy-viewer__fuelmix-view-option",
    );
    const legendContainer = document.querySelector("#fuelmix-legend-container");
    if (fuelmixViewSelector) {
      fuelmixViewSelector.addEventListener("click", (ev) => {
        if (ev.target.getAttribute("value")) {
          if (ev.target.getAttribute("value") == "daily") {
            // Remove hourly chart
            const container = document.querySelector(".energy-viewer__fuelmix");
            container.setAttribute("data-type", "daily");
            container.insertAdjacentHTML(
              "beforeend",
              '<div class="fuelmix__daily-container"><div id="fuelmix-daily-chart" style="min-height: 290px;"></div></div>',
            );
            const hourlyChart = document.querySelector("#fuelmix-hourly-chart");
            hourlyChart.remove();
            // Empty legend container
            legendContainer.innerHTML = "";
            // Create chart
            this.fuelmixDailyChart();
          }
          if (ev.target.getAttribute("value") == "hourly") {
            document
              .querySelector(".energy-viewer__fuelmix")
              .setAttribute("data-type", "hourly");
            // Remove daily chart
            const container = document.querySelector(
              ".fuelmix__daily-container",
            );
            container.insertAdjacentHTML(
              "afterend",
              '<div id="fuelmix-hourly-chart" style="min-height: 290px"></div>',
            );
            container.remove();
            // Empty legend container
            legendContainer.innerHTML = "";
            // Create chart
            this.fuelmixHourlyChart();
          }
        }
      });
    }

    // Listen to currency selector
    const currencySelector = document.querySelector("#currencies");
    if (currencySelector) {
      currencySelector.addEventListener("change", (ev) => {
        // Set localStorage
        localStorage.setItem("currency", ev.target.value);
        // Update charts
        this.priceAndCarbonCharts();
      });
    }

    // Listen to region selector
    const regionSelector = document.querySelector("#regions");
    if (regionSelector) {
      regionSelector.addEventListener("change", (ev) => {
        // Set localStorage
        localStorage.setItem("region", ev.target.value);
        // Update charts
        this.regenerateCharts();
      });
    }

    // Listen to next date
    const currentDateEl = document.querySelector("#current-date");
    const nextButton = document.querySelector("#ev-date-next");
    const prevButton = document.querySelector("#ev-date-prev");
    const todayButton = document.querySelector("#today-button")
    const timeDisplay = document.querySelector("#timeDisplay");
    const defaultTimeStamp = 12;

    // Listen to next date
    if (nextButton) {
      nextButton.addEventListener("click", () => {
        prevButton.removeAttribute("disabled");
        // Maksimalt 4 dager frem i tid
        const currentDate = currentDateEl.dataset.date;

        const maxDate = new Date();
        maxDate.setDate(maxDate.getDate() + 4);
        const maxDiff = Math.floor(
          (maxDate - new Date(currentDate)) / (1000 * 3600 * 24),
        );
        if (maxDiff == 1) {
          nextButton.setAttribute("disabled", true);
        }

        const nextDate = nextButton.dataset.date;
        prevButton.dataset.date = currentDate;
        currentDateEl.dataset.date = nextDate;

        currentDateEl.firstChild.textContent = this.prettyDateFormat(new Date(nextDate)) + " ";

        document.querySelector("#timeDisplay").innerText = "- " + defaultTimeStamp.toString().padStart(2, "0") + ":00";

        let currentDateObj = new Date(nextDate);
        currentDateObj.setDate(currentDateObj.getDate() + 1);
        nextButton.dataset.date = this.dataDateFormat(currentDateObj);

        // Update all charts
        this.regenerateCharts();
      });
    }

    // Listen to previous date
    if (prevButton) {
      prevButton.addEventListener("click", () => {
        nextButton.removeAttribute("disabled");
        const currentDate = currentDateEl.dataset.date;
        // Calculate min past date (14 days ago)
        const minDate = new Date();
        minDate.setDate(minDate.getDate() - 15);
        const minDiff = Math.floor((new Date(currentDate) - minDate) / (1000 * 3600 * 24));

        if (minDiff === 1) {
          prevButton.setAttribute("disabled", true);
        }
        nextButton.setAttribute("data-date", currentDate);
        currentDateEl.setAttribute("data-date", prevButton.dataset.date);
        let prevDateObj = new Date(prevButton.getAttribute("data-date"));

        currentDateEl.firstChild.textContent = this.prettyDateFormat(new Date(prevDateObj)) + " ";

        document.querySelector("#timeDisplay").innerText = "- " + defaultTimeStamp.toString().padStart(2, "0") + ":00";

        prevDateObj.setDate(prevDateObj.getDate() - 1);
        prevButton.setAttribute("data-date", this.dataDateFormat(prevDateObj));

        // Update all charts
        this.regenerateCharts();
      });
    }

    // Listen to "today"-button
    if (todayButton) {
      todayButton.addEventListener("click", () => {
        const today = new Date();
        const todayFormatted = this.dataDateFormat(today);
        currentDateEl.setAttribute("data-date", todayFormatted);

        currentDateEl.firstChild.textContent = this.prettyDateFormat(today) + " ";

        document.querySelector("#timeDisplay").innerText = "- " + defaultTimeStamp.toString().padStart(2, "0") + ":00";

        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        nextButton.setAttribute("data-date", this.dataDateFormat(tomorrow));

        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        prevButton.setAttribute("data-date", this.dataDateFormat(yesterday));

        this.regenerateCharts();
      });
    }




    // Listen to calendar opener
    const datepicker = document.querySelector("#datepicker");
    const datepickerButton = document.querySelector("#datepicker-button");
    if (datepickerButton) {
      datepickerButton.addEventListener("click", () => {
        datepicker.showPicker();
      });
      // Listen to datepicker click
      datepicker.addEventListener("change", (ev) => {
        const clickedDate = new Date(ev.target.value);
        // Update currentDate label
        currentDateEl.firstChild.textContent = this.prettyDateFormat(clickedDate) + " ";

        document.querySelector("#timeDisplay").innerText = "- " + defaultTimeStamp.toString().padStart(2, "0") + ":00";
        // Update currentDate date-attribute
        currentDateEl.setAttribute(
          "data-date",
          this.dataDateFormat(clickedDate),
        );
        // Update prevButton date-attribute
        const prevDate = new Date(
          clickedDate.setDate(clickedDate.getDate() - 1),
        );
        prevButton.setAttribute("data-date", this.dataDateFormat(prevDate));
        // Update nextButton date-attribute
        const nextDate = new Date(
          clickedDate.setDate(clickedDate.getDate() + 2),
        );
        nextButton.setAttribute("data-date", this.dataDateFormat(nextDate));
        this.regenerateCharts();
      });
    }

    // Listen to fuelmix scroll in widget
    const widgetLegends = document.querySelector(
      '.enappsys[data-sidebar="true"] .energy-viewer__fuelmix-list',
    );
    if (widgetLegends) {
      widgetLegends.addEventListener("scroll", (ev) => {
        const el = ev.target;
        const isScrolledToBottom =
          el.scrollHeight < el.clientHeight + el.scrollTop + 1;
        const isScrolledToTop = isScrolledToBottom ? false : el.scrollTop === 0;
        el.classList.toggle("is-bottom-overflowing", !isScrolledToBottom);
        el.classList.toggle("is-top-overflowing", !isScrolledToTop);
      });
    }
  }

  // Regenerates all charts with current settings
  regenerateCharts() {
    const selectedChart = document.querySelector('[name="view"]:checked');
    if (selectedChart) {
      switch (selectedChart.value) {
        case "daily":
          this.fuelmixDailyChart();
          break;
        case "hourly":
          this.fuelmixHourlyChart();
          break;
      }
      // Update price and carbon charts
      this.priceAndCarbonCharts();
    } else {
      this.fuelmixDailyChart();
    }
  }

  setupFuelmixDailyChart({ labels, values, colors }) {
    const options = {
      chart: {
        type: "donut",
        events: {
          mounted: () => {
            const currentSelected = document.querySelector(
              ".energy-viewer__fuelmix-list .selected",
            );
            this.showText(currentSelected);
          },
          dataPointSelection: (event, chartContext, opts) => {
            const index = opts.dataPointIndex;
            const legendContainer = document.querySelector(
              ".energy-viewer__fuelmix-list",
            );
            const currentSelected = legendContainer.querySelector(".selected");
            if (currentSelected && index != -1) {
              currentSelected.classList.remove("selected");
              if (legendContainer && legendContainer.childNodes) {
                let newlySelected = legendContainer.childNodes[index];
                newlySelected.classList.add("selected");
                this.showText(newlySelected);
              }
            }
          },
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: 80, // size of whitespace inside donut
            labels: {
              show: false,
              value: {
                show: true,
                formatter: function (val) {
                  return val + "%";
                },
              },
              name: {
                show: true,
              },
            },
          },
        },
      },
      series: values,
      labels: labels,
      colors: colors,
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
    };

    var chart = new ApexCharts(
      document.querySelector("#fuelmix-daily-chart"),
      options,
    );
    // Add Legend listeners
    const fuelmixLegendContainer = document.querySelector(
      "#fuelmix-legend-container",
    );
    fuelmixLegendContainer.addEventListener("click", (ev) => {
      if (ev.target.hasAttribute("data-target")) {
        chart.toggleDataPointSelection(ev.target.getAttribute("data-index"));
        // Show text
        this.showText(ev.target);
        const currentSelected =
          fuelmixLegendContainer.querySelector(".selected");
        if (currentSelected == ev.target) return;
        if (currentSelected) currentSelected.classList.remove("selected");
        ev.target.classList.add("selected");
      }
    });

    chart.render();

    // Initial first active element
    const firstEl = document.querySelector(
      ".energy-viewer__fuelmix-list",
    ).firstElementChild;
    if (firstEl) {
      chart.toggleDataPointSelection(0);
      this.showText(firstEl);
      firstEl.classList.add("selected");
    }
  }

  // Handle chart clicks
  handleChartClick(dataPointIndex, chartContext, data) {
    const xValue = chartContext.w.globals.seriesX[0][dataPointIndex];
    const formattedTime = xValue.toString().padStart(2, "0") + ":00";

    const timeDisplayElement = document.querySelector("#timeDisplay");
    if (timeDisplayElement) {
      // Ensure "-" is always at the start
      timeDisplayElement.innerText = `- ${formattedTime}`;
    } else {
      // console.log("timeDisplay element not found.");
    }

    // Process each item from the chart data
    data.forEach((item) => {
      const el = document.querySelector(`[data-target="${item.key}"]`);
      const valueToSet = item.data[xValue];

      if (el) {
        const percentEl = el.querySelector(".enappsys__button-percent");
        if (percentEl) {
          percentEl.innerHTML = valueToSet.y.toFixed(1) + "MW";
        } else {
          console.warn("Button percent element not found for key:", item.key);
        }
      }
    });

    // Reorder the buttons in the legend based on values
    const legendContainer = document.querySelector(".energy-viewer__fuelmix-list");
    const buttons = Array.from(legendContainer.querySelectorAll(".enappsys__button"));

    buttons.sort((a, b) => {
      const getMWValue = (btn) =>
        parseFloat(btn.querySelector(".enappsys__button-percent").textContent.replace("MW", ""));
      return getMWValue(b) - getMWValue(a);
    });

    buttons.forEach((btn, index) => {
      btn.setAttribute("data-index", index);
      legendContainer.appendChild(btn);
    });
  }



  // Set up chart and handle default time selection
  setupFuelmixHourlyChart(data) {
    let maxAggregatedValue = this.findMaxValue(data) * 0.967;

    // Default time is 12:00
    let defaultTime = 12;
    const options = {
      chart: {
        type: "area",
        stacked: true,
        events: {
          click: (event, chartContext, config) => {
            const dataPointIndex = config.dataPointIndex;

            if (dataPointIndex !== -1) {
              this.handleChartClick(dataPointIndex, chartContext, data);
            } else {
              // console.log("Click occurred outside data points");
            }
          },
        },
        zoom: {
          enabled: false,
        },
      },
      series: data,
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      yaxis: {
        decimalsInFloat: false,
        max: maxAggregatedValue,
        min: 0,
        tickAmount: 4,
      },
      xaxis: {
        stepSize: 4,
        tickAmount: 6,
        labels: {
          formatter: function (param) {
            return param.toString().padStart(2, "0") + ":00";
          },
        },
      },
      fill: {
        type: "solid",
        opacity: 1,
      },
      tooltip: {
        enabled: true,
        hideEmptySeries: true,
        fillSeriesColor: false,
        theme: false,
        style: {
          fontSize: "12px",
        },
        items: {
          display: "none",
        },
        x: {
          show: false,
        },
        onDatasetHover: {
          highlitghtDataSeries: true,
        },
      },
    };

    var chart = new ApexCharts(
      document.querySelector("#fuelmix-hourly-chart"),
      options,
    );
    chart.render();

    // Wait for the chart to fully render, then set the default time
    setTimeout(() => {
      const seriesX = chart.w.globals.seriesX[0];
      const defaultIndex = seriesX.findIndex((x) => x === defaultTime);

      if (defaultIndex !== -1) {
        this.handleChartClick(defaultIndex, chart, data);
      } else {
        console.warn("Default time (12:00) not found in dataset.");
      }
    }, 100); // Delay to ensure chart is fully rendered
  }

  setupPriceChart(data) {
    const options = {
      series: [
        {
          name: data.currency.symbol,
          data: data.prices,
        },
      ],
      chart: {
        type: "line",
        height: 390,
        zoom: {
          enabled: false,
        },
      },
      colors: ["#01796E"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      yaxis: {
        decimalsInFloat: false,
        max: data.currency.max,
        min: data.currency.min,
        tickAmount: 4,
        labels: {
          formatter: (value) => {
            return data.currency.symbol + value;
          },
        },
      },
      xaxis: {
        tickAmount: 6,
        stepSize: 4,
        labels: {
          formatter: function (param) {
            return param.toString().padStart(2, "0") + ":" + "00";
          },
        },
      },
    };

    var chart = new ApexCharts(document.querySelector("#price-chart"), options);
    chart.render();
  }

  setupCarbonChart(data) {
    const options = {
      series: [
        {
          name: "CO<sub>2</sub> (g/kWh)",
          data: data.carbon,
        },
      ],
      chart: {
        height: 390,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      colors: ["#01796E"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        xaxis: "numeric",
        stepSize: 4,
        tickAmount: 6,
        labels: {
          formatter: function (param) {
            return param.toString().padStart(2, "0") + ":" + "00";
          },
        },
      },
      yaxis: {
        tickAmount: 4,
        max: data.carbon_max,
        min: 0,
      },
    };

    var chart = new ApexCharts(
      document.querySelector("#carbon-chart"),
      options,
    );
    chart.render();
  }

  showText(element) {
    this.addPieLabel();
    if (element) {
      const value = element.querySelector(
        ".enappsys__button-percent",
      ).innerText;
      const label = element.querySelector(".enappsys__button-label").innerText;

      const valueEl = document.querySelector(".pie-chart__percent");
      const labelEl = document.querySelector(".pie-chart__energy-type");
      labelEl.innerHTML = label;
      valueEl.innerHTML = value;
    }
  }

  addPieLabel() {
    const el = document.querySelector(".fuelmix__daily-container");
    const caption = document.querySelector(".pie-chart__caption");
    if (caption) {
      caption.remove();
    }
    el.insertAdjacentHTML(
      "beforeend",
      '<figcaption class="pie-chart__caption"><p class="pie-chart__percent"></p><h2 class="pie-chart__energy-type"></h2></figcaption>',
    );
  }

  dataDateFormat(date) {
    return (
      date.getFullYear() +
      "/" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      date.getDate().toString().padStart(2, "0")
    );
  }

  prettyDateFormat(date) {
    const locale = document.documentElement.lang;
    return date.toLocaleString(locale, {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  }

  findMaxValue(data) {
    let total = 0;
    data.forEach((row) => {
      const values = Object.values(row.data);
      let maxValue = 0;
      values.map((el) => {
        const valueFromObject = el.y;
        maxValue = Math.max(maxValue, valueFromObject);
      });
      total += maxValue;
    });
    return total;
  }
}

export default EnergyViewer;
